var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      ref: "excel-upload-input",
      staticClass: "excel-upload-input",
      attrs: { accept: ".xlsx, .xls", type: "file" },
      on: { change: _vm.handleClick }
    }),
    _c(
      "div",
      {
        staticClass: "drop",
        on: {
          dragenter: _vm.handleDragover,
          dragover: _vm.handleDragover,
          drop: _vm.handleDrop
        }
      },
      [
        _vm._v(" Drop excel file here or "),
        _c(
          "el-button",
          {
            staticStyle: { "margin-left": "16px" },
            attrs: { loading: _vm.loading, size: "mini", type: "primary" },
            on: { click: _vm.handleUpload }
          },
          [_vm._v("Browse")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }